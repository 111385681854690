
import { Component, Vue } from "vue-property-decorator";
import { saveRegist, login } from "../api/ApiPath";
@Component
export default class Regist extends Vue {

  RegistForm = {
    username: "",
    password: "",
    comfirmpassword:"",
    email:"",
    code:"",
    agree: false,

  }
  RegistRole = {
      username: [{required: true, message:"input username", trigger:'blur'}],
      password: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      { min: 6, message: '密码长度不能少于6位', trigger: 'blur' }
    ],
    confirmPassword: [
      { required: true, message: '请再次输入密码', trigger: 'blur' },
      { validator: this.validateConfirmPassword, trigger: 'blur' }
    ],
    email: [
      { message: '请输入电子邮件', trigger: 'blur' },
      { type: 'email', message: '请输入有效的电子邮件地址', trigger: ['blur', 'change'] }
    ],
    agreeTerms: [
      { required: true, message: '请同意商城用户协议', trigger: 'change' }
    ]
    }
    validateConfirmPassword(rule: any, value: string, callback: any) {
    if (value !== this.RegistForm.password) {
      callback(new Error('两次输入的密码不一致'));
    } else {
      callback();
    }
  }


  regist() {
    let isValid = true;
    // if (this.RegistForm.username == "") {
    //   this.$message.error("请输入用户信息");
    //   isValid = false;
    // } else if (
    //   this.RegistForm.password == "" ||
    //   // this.RegistForm.password != this.RegistForm.repassword
    // ) {
    //   this.$message.error("两次密码不一致");
    //   isValid = false;
    // }
    if (isValid == true) {
      saveRegist(
        this.RegistForm,
        (res) => {
          const { msg, data, status } = res;
          if (status === 200) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
        },
        (err) => {
          this.$message.error(err);
        }
      );
      //自动登录
    //   login(this.RegistForm, (res) => {
    //     const { data, status, msg } = res;
    //     if (status === 200) {
    //       var token = JSON.stringify(res.data[0]);
    //       localStorage && localStorage.setItem("token", token);
    //       this.$message.success(msg);
    //       location.href = "/";
    //     }
    //   });
     }
   }
  getCode(){

  }
  cancel(){

  }
}
